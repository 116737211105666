<template>
  <loader-icon class='loading-spinner'></loader-icon>
</template>

<script>
import { LoaderIcon } from 'vue-tabler-icons'

export default {
  name: 'LoadingSpinner',
  components: {
    LoaderIcon,
  },
}
</script>

<style lang='scss' scpoed>
.loading-spinner {
  animation: spin 1.3s linear infinite;
}

@keyframes spin {
  0%   { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
