<template>
  <div :class='pageClassByNavigationComponent'
    :style='minHeightStyle'>
    <component
      :is='pageNavigationComponent'
      :sidebar-links='sidebarConfigurations.sidebarLinks'
      :sidebar-page-title='sidebarConfigurations.sidebarPageTitle'
      :custom-background-stype='sidebarConfigurations.customBackgroundStyle' />
    <div class='flex-grow relative px-4 lg:px-0 py-8 lg:py-16'>
      <div class='flex flex-row justify-start items-stretch gap-x-4'>
        <div class='flex flex-col justify-center items-center bg-green-50 rounded-xl px-8 py-4 text-center text-green-900 w-24 lg:w-40'>
          <circle-check-icon class='h-10 w-10 block mx-auto' stroke-width='1.5' />
          <h3 class='mt-2 text-base lg:text-xl'>Signup Completed</h3>
        </div>
        <div class='flex flex-col justify-center items-center rounded-xl px-8 py-4 text-center w-24 lg:w-40'
          :class='registrationStatusBasedBg'>
          <component :is='registrationCompleteStatusIcon' class='h-10 w-10 block mx-auto' />
          <h3 class='mt-2 text-base lg:text-xl'>{{ registrationCompleteStatus }}</h3>
        </div>
        <div @click='goToAbstractSubmissions' 
          class='flex flex-col justify-center items-center bg-gray-50 rounded-xl px-8 py-4 text-center hover:shadow-md hover:border cursor-pointer w-24 lg:w-40'>
          <h3 class='mt-2 text-3xl'>{{abstractSubmissionCount}}</h3>
          <h3 class='mt-2 text-base lg:text-xl'>Abstract Submissions</h3>
        </div>
      </div>
      <div >
        <h2 class='text-xl font-semibold mt-12 mb-2'>Personal Information</h2>
        <edit-form-field
          class='mb-2 w-full lg:w-2/3' 
          v-for='formField in signupFormFields'
          :key='formField.keyName'
          :edit-form-field='formField'
          :show-requirement-indicator-on-top='showRequirementIndicatorOnTop'
          :is-horizontal-layout='isFormFieldsHorizontalLayout'
          :value='fieldValue(formField.category, formField.keyName)'
          :requirement-indicator-helper-text='requirementIndicatorHelperText'
          @update-edit-form-field-value='updateSignupFormFieldValue(formField.category, formField.keyName, ...arguments)' />
      </div>
      <div>
        <h2 class='text-xl font-semibold mt-12 mb-2'>Registration Information</h2>
        <div v-if='isRegisteredUser'>
          <edit-form-field
            class='mb-2 w-full lg:w-2/3' 
            v-for='formField in registrationFormFields'
            :key='formField.keyName'
            :edit-form-field='formField'
            :show-requirement-indicator-on-top='showRequirementIndicatorOnTop'
            :is-horizontal-layout='isFormFieldsHorizontalLayout'
            :value='fieldValue(formField.category, formField.keyName)'
            :requirement-indicator-helper-text='requirementIndicatorHelperText'
            @update-edit-form-field-value='updateSignupFormFieldValue(formField.category, formField.keyName, ...arguments)' />
        </div>
        <div v-else class='bg-gray-100 p-8 mb-4 lg:mb-8 opacity-70'>
          Registration has not been completed.
        </div>
      </div>
      <div class='mt-12'>
        <button 
          @click='saveUpdates'
          :disabled='disabledMyPageDataUpdatesButton'
          :class='disabledMyPageDataUpdatesButtonClass'
          class='style-primary w-full lg:w-auto lg:px-32 py-3 rounded-md text-white font-semibold'
          :style='`background-color: ${eventMainThemeColor}`'>
          <loading-spinner v-if='isLoading' />
          <span v-else>Update Information</span>
        </button>
      </div>
      <div class='mt-6'>
        <ul v-if='!showRequirementIndicatorOnTop'
          class='list-disc list-inside text-red-600 text-sm'>
          <div v-for='formField in signupFormFields'
            :key='formField.keyName'>
            <li v-if='isFieldValutEmpty(formField)'>
              {{ formField.name }} {{requirementIndicatorHelperText}}
            </li>
          </div>
        </ul>
        <ul class='list-disc list-inside text-red-600 text-sm'>
          <div v-for='requirement in additionalRequirementsIndicators'
            :key='requirement.keyName'>
            <li>
              {{ requirement.metaData.indicatorText }}
            </li>
          </div>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { CircleCheckIcon, CircleDottedIcon } from 'vue-tabler-icons'
import camelCase from 'lodash/camelCase'
import cloneDeep from 'lodash/cloneDeep'
import jsonLogic from 'json-logic-js'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import dayjs from 'dayjs'

const camelcaseKeys = require('camelcase-keys')

export default {
  name: 'MyPage',
  components: {
    CircleCheckIcon,
    CircleDottedIcon,
    LoadingSpinner,
    'tabs-page-navigation': () => import('@/components/common/TabsPageNavigation'),
    'sidebar-page-navigation': () => import('@/components/common/SidebarPageNavigation'),
    'edit-form-field': () => import('@/components/edit-form/EditFormField.vue'), 
  },
  props: {
  },
  data () {
    return {
      validPhoneNumbers: [],
      abstractSubmissionCount: 0,
      isLoading: false,
    }
  },
  watch: {
    'profileDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.profile = cloneDeep(newVal)
          this.getProfile().then((resp) => {
            Object.keys(this.profile).forEach(key => {
              if (key === 'extraInfo') {
                let dieteLicenseNumber = this.profile[key].dieteLicenseNumber ? this.profile[key].dieteLicenseNumber : ''
                let degree = this.profile[key].degree ? this.profile[key].degree : []
                let hospitalType = this.profile[key].hospitalType ? this.profile[key].hospitalType : ''
                let medicalProfession = this.profile[key].medicalProfession ? this.profile[key].medicalProfession : ''
                let specialistLicenseNumber = this.profile[key].specialistLicenseNumber ? this.profile[key].specialistLicenseNumber : ''
                let conferenceFactor = this.profile[key].conferenceFactor ? this.profile[key].conferenceFactor : ''
                this.profile[key] = {
                  'degree': degree,
                  'hospitalType': hospitalType,
                  'conferenceFactor': conferenceFactor,
                  'medicalProfession': medicalProfession,
                  'dieteLicenseNumber': dieteLicenseNumber,
                  'specialistLicenseNumber': specialistLicenseNumber
                }
              } else if (key === 'phoneNumber') {
                let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === 'phone_number')
                if (index > -1) {
                  this.validPhoneNumbers[index].phoneNumber = resp[key]
                  this.validPhoneNumbers[index].isValidPhoneNumber = true
                } else {
                  this.validPhoneNumbers.push({
                    keyName: 'phone_number',
                    phoneNumber: resp[key],
                    isValidPhoneNumber: true
                  })
                }
                this.profile[key] = resp[key]
              } else {
                this.profile[key] = resp[key]
              }
            })
          })
        }
      },
      immediate: true,
      deep: true
    },
    'registrationDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.registrationData = cloneDeep(newVal)
          this.getRegistrationData().then((resp) => {
            Object.keys(this.registrationData).forEach(key => {
              this.registrationData[key] = resp[key]
            })
          })
        }
      },
      immediate: true,
      deep: true
    },
    'membershipDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.membershipData = cloneDeep(newVal)
          this.getMembershipData().then((resp) => {
            Object.keys(this.membershipData).forEach(key => {
              this.membershipData[key] = resp[key]
            })
          })
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    ...mapFields('users', [
      'signupParams',
      'profile',
      'registrationData',
      'membershipData',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'showingEventSignupConfigurations',
      'showingEventRegistrationConfigurations',
      'eventMainThemeColor',
    ]),
    pageNavigationComponent () {
      return 'tabs-page-navigation'
    },
    pageClassByNavigationComponent () {
      return this.pageNavigationComponent === 'sidebar-page-navigation' ? 'flex flex-col lg:flex-row justify-between items-stretch gap-x-16' : ''
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    profileDefault () {
      return camelcaseKeys({
        "name": "",
        "title": "",
        "country": "",
        "last_name": "",
        "birth_date": "",
        "department": "",
        "extra_info": {
          "degree": '',
          "hospital_type": "",
          "conference_factor": "",
          "medical_profession": "",
          "diete_license_number": "",
          "specialist_license_number": ""
        },
        "first_name": "",
        "organization": "",
        "phone_number": "",
        "hospital_name": "",
        "license_number": ""
      }, {deep:true})
    },
    registrationDataDefault () {
      return camelcaseKeys({
        "special_requests_for_food": '',
        "lunch": [],
        "paid_at": '',
        "payment": false,
        "breakfast": [],
        "depositor": "",
        "payment_gateway": "",
        "registration_fee": "",
        "welcome_reception": "",
        "expected_deposit_date": ""
      }, {deep: true})
    },
    membershipDataDefault () {
      return camelcaseKeys({
        "is_icola_member": ''
      }, {deep: true})
    },
    registrationCompleteStatusIcon () {
      return this.registrationData.payment ? 'circle-check-icon' : 'circle-dotted-icon'
    },
    registrationCompleteStatus () {
      return this.registrationData.payment ? 'Registration Completed' : 'Registration Incomplete' 
    },
    registrationStatusBasedBg () {
      return this.registrationData.payment ? 'bg-green-50' : 'bg-gray-50 opacity-60' 
    },
    currentDateTimeString () {
      return dayjs().format('YYYYMMDDHHmm')
    },
    signupFormFields () {
      let signupFormFields = (this.showingEventSignupConfigurations && this.showingEventSignupConfigurations.signupFormFields) ?
        this.showingEventSignupConfigurations.signupFormFields.concat()
        .filter((field) => field.keyName !== 'email' && field.keyName !== 'password' && field.keyName !== 'password_confirmation')
        .filter((field) => {
          let condition = field.metaData.showCondition ? field.metaData.showCondition : true
          return jsonLogic.apply(condition, {registrationData: this.registrationData, profile: this.profile, currentDateTime: this.currentDateTimeString})
        })
        .sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder) : []
      signupFormFields.forEach((field) => {
        if (field.keyName === 'country' || field.keyName === 'name' || field.keyName == 'is_icola_member') {
          field.inputType = 'read_only'
        }
      })
      return signupFormFields
    },
    registrationFormFields () {
      let registrationFormFields = this.showingEventRegistrationConfigurations.registrationFormFields.concat()
        .filter((field) => ['lunch', 'breakfast', 'welcome_reception', 'payment_gateway', 'depositor', 'expected_deposit_date'].includes(field.keyName))

      registrationFormFields = registrationFormFields.map(field => {
        if (field.keyName === 'payment_gateway') {
          field.metaData.showingTitlesByValue = [
            {
              "value": "bank_transfer",
              "title": "Bank Transfer"
            },
            {
              "value": "nhnkcp-payment",
              "title": "Credit Card"
            },
          ]
        }
        field.metaData.helperText = ''
        field.inputType = 'read_only'
        return field
      })

      registrationFormFields.push(
      camelcaseKeys({
        "name": "Total Regisration Fee",
        "category": "registration_data",
        "key_name": "registration_fee",
        "meta_data": {
          "helper_text": "",
          "placeholder": "",
          "selectable_options": null
        },
        "input_type": "read_only",
        "sort_order": 101
      }, {deep: true}),
      camelcaseKeys({
        "name": "Payment Confirmation Date",
        "category": "registration_data",
        "key_name": "paid_at",
        "meta_data": {
          "is_datetime": true,
          "helper_text": "",
          "show_condition": {
            "===": [
              {
                "var": "registrationData.paymentGateway"
              },
              "nhnkcp-payment"
            ]
          },
          "selectable_options": null,
        },
        "input_type": "read_only",
        "sort_order": 102
      }, {deep: true}),
      camelcaseKeys({
        "name": "Deposit Confirmation Date",
        "category": "registration_data",
        "key_name": "paid_at",
        "meta_data": {
          "is_datetime": true,
          "helper_text": "",
          "show_condition": {
            "===": [
              {
                "var": "registrationData.paymentGateway"
              },
              "bank_transfer"
            ]
          },
          "selectable_options": null,
        },
        "input_type": "read_only",
        "sort_order": 103
      }, {deep: true}),
      camelcaseKeys({
        "name": "Payment Status",
        "category": "registration_data",
        "key_name": "payment",
        "meta_data": {
          "helper_text": "",
          "placeholder": "",
          "selectable_options": null,
          "showing_titles_by_value": [
            {
              "value": true,
              "title": "Payment Done"
            },
            {
              "value": false,
              "title": "Payment Pending"
            },
          ]
        },
        "input_type": "read_only",
        "sort_order": 104
      }, {deep: true}),
      )

      return registrationFormFields.filter((field) => {
        let condition = field.metaData.showCondition ? field.metaData.showCondition : true
        return jsonLogic.apply(condition, {registrationData: this.registrationData, profile: this.profile, currentDateTime: this.currentDateTimeString})
      })
    },
    sidebarConfigurations () {
      return {
        'sidebarLinks': [
          {
            'link': 'AbstractSubmissions',
            'title': 'My Abstracts',
            'actionType': 'open_internal_route'
          },
        ],
        'customLinkStyle': '',
        'sidebarPageTitle': '',
        'customBackgroundStyle': ''
      }
    },
    isFormFieldsHorizontalLayout () {
      return true
    },
    showRequirementIndicatorOnTop () {
      return false
    },
    requirementIndicatorHelperText () {
      return 'is required.'
    },
    needToCheckPhoneNumber () {
      return !this.validPhoneNumbers.every(phoneNumber => phoneNumber.isValidPhoneNumber)
    },
    additionalRequirements () {
      return {
        needToCheckPhoneNumber: this.needToCheckPhoneNumber
      }
    },
    additionalRequirementsIndicators () {
      let additionalRequirementsIndicators = camelcaseKeys([
        {
          "key_name": "needToCheckPhoneNumber",
          "metaData": {
            "indicator_text": "Please check your phone number format.",
            "show_condition": {
              "==": [
                {
                  "var": "additionalRequirements.needToCheckPhoneNumber"
                },
                true
              ]
            }
          }
        },
      ], {deep: true})
      return additionalRequirementsIndicators.concat()
        .filter((requirement) => {
          let condition = requirement.metaData.showCondition ? requirement.metaData.showCondition : false
          return jsonLogic.apply(condition, {additionalRequirements: this.additionalRequirements})
        })
    },
    disabledMyPageDataUpdatesButton () {
      return this.signupFormFields.map(field => this.fieldValue(field.category, field.keyName)).some(value => !value ||
        typeof value === 'string' && !value ||
        typeof value === 'object' && !Object.keys(value).length ||
        Array.isArray(value) && !value.length) ||
      Object.values(this.additionalRequirements).some(requirement => requirement)
    },
    disabledMyPageDataUpdatesButtonClass () {
      return this.disabledMyPageDataUpdatesButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    isRegisteredUser () {
      return this.registrationData.payment
    },
  },
  methods: {
    ...mapActions('abstracts', [
      'getMyAbstracts',
    ]),
    ...mapActions('users', [
      'logout',
      'getProfile',
      'getMembershipData',
      'getRegistrationData',
      'patchProfile',
      'patchRegistrationData',
    ]),
    isRequiredField (keyName) {
      return this.registrationFormFields.find(field => field.keyName === keyName)
    },
    fieldValue (category, keyName) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      let val = ''
      if (category === 'profile') {
        if (arr.length === 2) {
          if (keyName.includes('phone_number')) { 
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            val = index > -1 && this.profile[arr[0]][arr[1]] ? {
              phoneNumber: this.validPhoneNumbers[index].phoneNumber,
              isValidPhoneNumber: this.validPhoneNumbers[index].isValidPhoneNumber
            } : null
          } else {
            val = this.profile[arr[0]][arr[1]]
          }
        } else if (arr.length === 1) {
          if (keyName === 'address') {
            val = this.profile.address && this.profile.postalCode ? {
              address: this.profile.address,
              postalCode: this.profile.postalCode,
            } : null
          } else if (keyName === 'phone_number') {
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === 'phone_number')
            val = index > -1 && this.profile[arr[0]] ? {
                phoneNumber: this.validPhoneNumbers[index].phoneNumber,
                isValidPhoneNumber: this.validPhoneNumbers[index].isValidPhoneNumber
            } : null
          } else if (keyName === 'organization_hospital_name') {
            val = this.profile.organization && this.profile.hospitalName ? {
              organization: this.profile.organization,
              hospitalName: this.profile.hospitalName,
            } : null
          } else {
            val = this.profile[arr[0]]
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          val = this.signupParams[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.signupParams[arr[0]]
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          val = this.registrationData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.registrationData[arr[0]]
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          val = this.membershipData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.membershipData[arr[0]]
        }
      }
      return val
    },
    updateSignupFormFieldValue (category, keyName, value) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      if (category === 'profile') {
        if (arr.length === 2) { //extra info
          if (keyName.includes('phone_number')) { 
            this.profile[arr[0]][arr[1]] = value.phoneNumber
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            if (index > -1) {
              this.validPhoneNumbers[index].phoneNumber = value.phoneNumber
              this.validPhoneNumbers[index].isValidPhoneNumber = value.isValidPhoneNumber
            } else {
              this.validPhoneNumbers.push({
                keyName: keyName,
                phoneNumber: value.phoneNumber,
                isValidPhoneNumber: value.isValidPhoneNumber
              })
            }
          } else {
            this.profile[arr[0]][arr[1]] = value
          }
        } else if (arr.length === 1) {
          if (keyName === 'phone_number') {
            this.profile.phoneNumber = value.phoneNumber
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            if (index > -1) {
              this.validPhoneNumbers[index].phoneNumber = value.phoneNumber
              this.validPhoneNumbers[index].isValidPhoneNumber = value.isValidPhoneNumber
            } else {
              this.validPhoneNumbers.push({
                keyName: keyName,
                phoneNumber: value.phoneNumber,
                isValidPhoneNumber: value.isValidPhoneNumber
              })
            }
          } else if (keyName === 'address') {
            this.profile.address = value.address
            this.profile.postalCode = value.postalCode
          } else if (keyName === 'organization_hospital_name') {
            this.profile.organization = value.organization
            this.profile.hospitalName = value.hospitalName
          } else {
            this.profile[arr[0]] = value
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          this.signupParams[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.signupParams[arr[0]] = value
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          this.registrationData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.registrationData[arr[0]] = value
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          this.membershipData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.membershipData[arr[0]] = value
        }
      }
    },
    goToAbstractSubmissions () {
      this.$router.push({name: 'AbstractSubmissions'})
    },
    saveUpdates () {
      this.isUpdating = true
      const promiseList = []
      promiseList.push(this.patchProfile(this.profile))
      promiseList.push(this.patchRegistrationData(this.registrationData))
      Promise.all(promiseList).then(() => {
        this.isUpdating = false
        this.$alert('Your information has been saved successfully.', {
          confirmButtonText: 'Close',
          type: 'success',
          showClose: false,
        }).then(() => {
          // do nothing
        }).catch(() => {})
      })
    },
    isFieldValutEmpty (field) {
      let value = this.fieldValue(field.category, field.keyName)
      return !value ||
        typeof value === 'string' && !value ||
        typeof value === 'object' && !Object.keys(value).length ||
        Array.isArray(value) && !value.length
    },
  },
  mounted () {
    this.getMyAbstracts(this.showingEventId).then(resp => {
      this.abstractSubmissionCount = resp.abstract_submissions.length
    })
  }
}
</script>

<style lang='scss' scoped>
  .logout-button {
    transition: all 0.3s ease-in-out;
  }

.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold border rounded-md;
}
    
@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}
</style>
